import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    DynamicLabel,
    noZeroRequiredValidator,
    InputTypes,
    NumericInputTypes,
    SelectInputTypes,
} from '@mt-ng2/dynamic-form';
import { getMetaItemValue } from '@mt-ng2/common-functions';
import { IMetaItem } from '../interfaces/base';

import { IExpandableObject } from '../expandable-object';
import { IImedClaim } from '../interfaces/imed-claim';
import { IAddressBook } from '../interfaces/address-book';
import { IIndividual } from '../interfaces/individual';
import { IImedClaimType } from '../interfaces/imed-claim-type';
import { IUser } from '../interfaces/user';
import { IImedClaimStatus } from '../interfaces/imed-claim-status';
import { IImage } from '../interfaces/image';
import { IRequestorType } from '../interfaces/requestor-type';
import { IState } from '../interfaces/state';

export interface IImedClaimDynamicControlsParameters {
    formGroup?: string;
    paraLegalAddressBooks?: IAddressBook[];
    defenseAttorneyAddressBooks?: IAddressBook[];
    plantiffAttorneyAddressBooks?: IAddressBook[];
    carrierPartyAddressBooks?: IAddressBook[];
    adjusterAddressBooks?: IAddressBook[];
    otherAddressBooks?: IAddressBook[];
    paraLegalIndividuals?: IIndividual[];
    defenseAttorneyIndividuals?: IIndividual[];
    plantiffAttorneyIndividuals?: IIndividual[];
    carrierPartyIndividuals?: IIndividual[];
    adjusterIndividuals?: IIndividual[];
    otherIndividuals?: IIndividual[];
    imedClaimStatuses?: IImedClaimStatus[];
    letterHeadImages?: IImage[];
    createdBies?: IUser[];
    modifiedBies?: IUser[];
    requestorTypes?: IRequestorType[];
    claimTypes?: IImedClaimType[];
    claimantsAttorneyAddressBooks?: IAddressBook[];
    claimantsAttorneyIndividuals?: IIndividual[];
    carriersCounselAddressBooks?: IAddressBook[];
    carriersCounselIndividuals?: IIndividual[];
    employerAddressBooks?: IAddressBook[];
    employerIndividuals?: IIndividual[];
    schedulingAssignedTos?: IUser[];
    compilationAssignedTos?: IUser[];
    reportAssignedTos?: IUser[];
    states?: IState[];
}

export class ImedClaimDynamicControls {

    formGroup: string;
    paraLegalAddressBooks?: IAddressBook[];
    defenseAttorneyAddressBooks?: IAddressBook[];
    plantiffAttorneyAddressBooks?: IAddressBook[];
    carrierPartyAddressBooks?: IAddressBook[];
    adjusterAddressBooks?: IAddressBook[];
    otherAddressBooks?: IAddressBook[];
    paraLegalIndividuals?: IIndividual[];
    defenseAttorneyIndividuals?: IIndividual[];
    plantiffAttorneyIndividuals?: IIndividual[];
    carrierPartyIndividuals?: IIndividual[];
    adjusterIndividuals?: IIndividual[];
    otherIndividuals?: IIndividual[];
    imedClaimStatuses?: IImedClaimStatus[];
    letterHeadImages?: IImage[];
    createdBies?: IUser[];
    modifiedBies?: IUser[];
    requestorTypes?: IRequestorType[];
    claimTypes?: IImedClaimType[];
    claimantsAttorneyAddressBooks?: IAddressBook[];
    claimantsAttorneyIndividuals?: IIndividual[];
    carriersCounselAddressBooks?: IAddressBook[];
    carriersCounselIndividuals?: IIndividual[];
    employerAddressBooks?: IAddressBook[];
    employerIndividuals?: IIndividual[];
    schedulingAssignedTos?: IUser[];
    compilationAssignedTos?: IUser[];
    reportAssignedTos?: IUser[];
    states?: IState[];

    Form: IExpandableObject;
    View: IExpandableObject;

    constructor(private imedclaim?: IImedClaim, additionalParameters?: IImedClaimDynamicControlsParameters) {
        this.formGroup = additionalParameters && additionalParameters.formGroup || 'ImedClaim';
        this.paraLegalAddressBooks = additionalParameters && additionalParameters.paraLegalAddressBooks || undefined;
        this.defenseAttorneyAddressBooks = additionalParameters && additionalParameters.defenseAttorneyAddressBooks || undefined;
        this.plantiffAttorneyAddressBooks = additionalParameters && additionalParameters.plantiffAttorneyAddressBooks || undefined;
        this.carrierPartyAddressBooks = additionalParameters && additionalParameters.carrierPartyAddressBooks || undefined;
        this.adjusterAddressBooks = additionalParameters && additionalParameters.adjusterAddressBooks || undefined;
        this.otherAddressBooks = additionalParameters && additionalParameters.otherAddressBooks || undefined;
        this.paraLegalIndividuals = additionalParameters && additionalParameters.paraLegalIndividuals || undefined;
        this.defenseAttorneyIndividuals = additionalParameters && additionalParameters.defenseAttorneyIndividuals || undefined;
        this.plantiffAttorneyIndividuals = additionalParameters && additionalParameters.plantiffAttorneyIndividuals || undefined;
        this.carrierPartyIndividuals = additionalParameters && additionalParameters.carrierPartyIndividuals || undefined;
        this.adjusterIndividuals = additionalParameters && additionalParameters.adjusterIndividuals || undefined;
        this.otherIndividuals = additionalParameters && additionalParameters.otherIndividuals || undefined;
        this.imedClaimStatuses = additionalParameters && additionalParameters.imedClaimStatuses || undefined;
        this.letterHeadImages = additionalParameters && additionalParameters.letterHeadImages || undefined;
        this.createdBies = additionalParameters && additionalParameters.createdBies || undefined;
        this.modifiedBies = additionalParameters && additionalParameters.modifiedBies || undefined;
        this.requestorTypes = additionalParameters && additionalParameters.requestorTypes || undefined;
        this.claimTypes = additionalParameters && additionalParameters.claimTypes || undefined;
        this.claimantsAttorneyAddressBooks = additionalParameters && additionalParameters.claimantsAttorneyAddressBooks || undefined;
        this.claimantsAttorneyIndividuals = additionalParameters && additionalParameters.claimantsAttorneyIndividuals || undefined;
        this.carriersCounselAddressBooks = additionalParameters && additionalParameters.carriersCounselAddressBooks || undefined;
        this.carriersCounselIndividuals = additionalParameters && additionalParameters.carriersCounselIndividuals || undefined;
        this.employerAddressBooks = additionalParameters && additionalParameters.employerAddressBooks || undefined;
        this.employerIndividuals = additionalParameters && additionalParameters.employerIndividuals || undefined;
        this.schedulingAssignedTos = additionalParameters && additionalParameters.schedulingAssignedTos || undefined;
        this.compilationAssignedTos = additionalParameters && additionalParameters.compilationAssignedTos || undefined;
        this.reportAssignedTos = additionalParameters && additionalParameters.reportAssignedTos || undefined;
        this.states = additionalParameters && additionalParameters.states || undefined;

        this.Form = {
            AdditionalInformation: new DynamicField({
                formGroup: this.formGroup,
                label: 'Additional Information',
                name: 'AdditionalInformation',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.imedclaim && this.imedclaim.hasOwnProperty('AdditionalInformation') && this.imedclaim.AdditionalInformation != null ? this.imedclaim.AdditionalInformation.toString() : '',
            }),
            AdjusterAddressBookId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Adjuster Address Book',
                name: 'AdjusterAddressBookId',
                options: this.adjusterAddressBooks,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.AdjusterAddressBookId || null,
            }),
            AdjusterIndividualId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Adjuster Individual',
                name: 'AdjusterIndividualId',
                options: this.adjusterIndividuals,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.AdjusterIndividualId || null,
            }),
            AdjusterIsDefault: new DynamicField({
                formGroup: this.formGroup,
                label: 'Adjuster Is Default',
                name: 'AdjusterIsDefault',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.hasOwnProperty('AdjusterIsDefault') && this.imedclaim.AdjusterIsDefault != null ? this.imedclaim.AdjusterIsDefault : false,
            }),
            AllStates: new DynamicField({
                formGroup: this.formGroup,
                label: 'All States',
                name: 'AllStates',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.hasOwnProperty('AllStates') && this.imedclaim.AllStates != null ? this.imedclaim.AllStates : true,
            }),
            Archived: new DynamicField({
                formGroup: this.formGroup,
                label: 'Archived',
                name: 'Archived',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.hasOwnProperty('Archived') && this.imedclaim.Archived != null ? this.imedclaim.Archived : false,
            }),
            AssociatedDomain: new DynamicField({
                formGroup: this.formGroup,
                label: 'Associated Domain',
                name: 'AssociatedDomain',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(50) ],
                validators: { 'maxlength': 50 },
                value: this.imedclaim && this.imedclaim.hasOwnProperty('AssociatedDomain') && this.imedclaim.AssociatedDomain != null ? this.imedclaim.AssociatedDomain.toString() : '',
            }),
            BeginningPageCount: new DynamicField({
                formGroup: this.formGroup,
                label: 'Beginning Page Count',
                name: 'BeginningPageCount',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.BeginningPageCount || null,
            }),
            Birthdate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Birthdate',
                name: 'Birthdate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.Birthdate || null,
            }),
            CarrierPartyAddressBookId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Carrier Party Address Book',
                name: 'CarrierPartyAddressBookId',
                options: this.carrierPartyAddressBooks,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.CarrierPartyAddressBookId || null,
            }),
            CarrierPartyIndividualId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Carrier Party Individual',
                name: 'CarrierPartyIndividualId',
                options: this.carrierPartyIndividuals,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.CarrierPartyIndividualId || null,
            }),
            CarrierPartyIsDefault: new DynamicField({
                formGroup: this.formGroup,
                label: 'Carrier Party Is Default',
                name: 'CarrierPartyIsDefault',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.hasOwnProperty('CarrierPartyIsDefault') && this.imedclaim.CarrierPartyIsDefault != null ? this.imedclaim.CarrierPartyIsDefault : false,
            }),
            CarriersCounselAddressBookId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Carriers Counsel Address Book',
                name: 'CarriersCounselAddressBookId',
                options: this.carriersCounselAddressBooks,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.CarriersCounselAddressBookId || null,
            }),
            CarriersCounselIndividualId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Carriers Counsel Individual',
                name: 'CarriersCounselIndividualId',
                options: this.carriersCounselIndividuals,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.CarriersCounselIndividualId || null,
            }),
            CarriersCounselIsDefault: new DynamicField({
                formGroup: this.formGroup,
                label: 'Carriers Counsel Is Default',
                name: 'CarriersCounselIsDefault',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.hasOwnProperty('CarriersCounselIsDefault') && this.imedclaim.CarriersCounselIsDefault != null ? this.imedclaim.CarriersCounselIsDefault : false,
            }),
            CaseSettledDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Case Settled Date',
                name: 'CaseSettledDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.CaseSettledDate || null,
            }),
            CaseTransferredDate: new DynamicField({
                formGroup: this.formGroup,
                label: 'Case Transferred Date',
                name: 'CaseTransferredDate',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.CaseTransferredDate || null,
            }),
            CausalRelationship: new DynamicField({
                formGroup: this.formGroup,
                label: 'Causal Relationship',
                name: 'CausalRelationship',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.hasOwnProperty('CausalRelationship') && this.imedclaim.CausalRelationship != null ? this.imedclaim.CausalRelationship : false,
            }),
            ClaimantsAttorneyAddressBookId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Claimants Attorney Address Book',
                name: 'ClaimantsAttorneyAddressBookId',
                options: this.claimantsAttorneyAddressBooks,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.ClaimantsAttorneyAddressBookId || null,
            }),
            ClaimantsAttorneyIndividualId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Claimants Attorney Individual',
                name: 'ClaimantsAttorneyIndividualId',
                options: this.claimantsAttorneyIndividuals,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.ClaimantsAttorneyIndividualId || null,
            }),
            ClaimantsAttorneyIsDefault: new DynamicField({
                formGroup: this.formGroup,
                label: 'Claimants Attorney Is Default',
                name: 'ClaimantsAttorneyIsDefault',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.hasOwnProperty('ClaimantsAttorneyIsDefault') && this.imedclaim.ClaimantsAttorneyIsDefault != null ? this.imedclaim.ClaimantsAttorneyIsDefault : false,
            }),
            ClaimNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Claim Number',
                name: 'ClaimNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(150) ],
                validators: { 'maxlength': 150 },
                value: this.imedclaim && this.imedclaim.hasOwnProperty('ClaimNumber') && this.imedclaim.ClaimNumber != null ? this.imedclaim.ClaimNumber.toString() : '',
            }),
            ClaimTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Claim Type',
                name: 'ClaimTypeId',
                options: this.claimTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.ClaimTypeId || 1,
            }),
            CompilationAssignedToId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Compilation Assigned To',
                name: 'CompilationAssignedToId',
                options: this.compilationAssignedTos,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.CompilationAssignedToId || null,
            }),
            CreatedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Created By',
                name: 'CreatedById',
                options: this.createdBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.imedclaim && this.imedclaim.CreatedById || null,
            }),
            CurrentCourseOfTreatment: new DynamicField({
                formGroup: this.formGroup,
                label: 'Current Course Of Treatment',
                name: 'CurrentCourseOfTreatment',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.hasOwnProperty('CurrentCourseOfTreatment') && this.imedclaim.CurrentCourseOfTreatment != null ? this.imedclaim.CurrentCourseOfTreatment : true,
            }),
            CurrentSymptomsAndCompliants: new DynamicField({
                formGroup: this.formGroup,
                label: 'Current Symptoms And Compliants',
                name: 'CurrentSymptomsAndCompliants',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.hasOwnProperty('CurrentSymptomsAndCompliants') && this.imedclaim.CurrentSymptomsAndCompliants != null ? this.imedclaim.CurrentSymptomsAndCompliants : true,
            }),
            DateCreated: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Created',
                name: 'DateCreated',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.DateCreated || null,
            }),
            DateModified: new DynamicField({
                formGroup: this.formGroup,
                label: 'Date Modified',
                name: 'DateModified',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.DateModified || null,
            }),
            DateofAllegedAccidentOrInjury: new DynamicField({
                formGroup: this.formGroup,
                label: 'Dateof Alleged Accident Or Injury',
                name: 'DateofAllegedAccidentOrInjury',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.DateofAllegedAccidentOrInjury || null,
            }),
            DefenseAttorneyAddressBookId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Defense Attorney Address Book',
                name: 'DefenseAttorneyAddressBookId',
                options: this.defenseAttorneyAddressBooks,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.DefenseAttorneyAddressBookId || null,
            }),
            DefenseAttorneyIndividualId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Defense Attorney Individual',
                name: 'DefenseAttorneyIndividualId',
                options: this.defenseAttorneyIndividuals,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.DefenseAttorneyIndividualId || null,
            }),
            DefenseAttorneyIsDefault: new DynamicField({
                formGroup: this.formGroup,
                label: 'Defense Attorney Is Default',
                name: 'DefenseAttorneyIsDefault',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.hasOwnProperty('DefenseAttorneyIsDefault') && this.imedclaim.DefenseAttorneyIsDefault != null ? this.imedclaim.DefenseAttorneyIsDefault : false,
            }),
            DegreeOfDisability: new DynamicField({
                formGroup: this.formGroup,
                label: 'Degree Of Disability',
                name: 'DegreeOfDisability',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.hasOwnProperty('DegreeOfDisability') && this.imedclaim.DegreeOfDisability != null ? this.imedclaim.DegreeOfDisability : false,
            }),
            DocumentFolder: new DynamicField({
                formGroup: this.formGroup,
                label: 'Document Folder',
                name: 'DocumentFolder',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(100) ],
                validators: { 'maxlength': 100 },
                value: this.imedclaim && this.imedclaim.hasOwnProperty('DocumentFolder') && this.imedclaim.DocumentFolder != null ? this.imedclaim.DocumentFolder.toString() : '',
            }),
            EmployerAddressBookId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Employer Address Book',
                name: 'EmployerAddressBookId',
                options: this.employerAddressBooks,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.EmployerAddressBookId || null,
            }),
            EmployerIndividualId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Employer Individual',
                name: 'EmployerIndividualId',
                options: this.employerIndividuals,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.EmployerIndividualId || null,
            }),
            EmployerIsDefault: new DynamicField({
                formGroup: this.formGroup,
                label: 'Employer Is Default',
                name: 'EmployerIsDefault',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.hasOwnProperty('EmployerIsDefault') && this.imedclaim.EmployerIsDefault != null ? this.imedclaim.EmployerIsDefault : false,
            }),
            EndingPageCount: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ending Page Count',
                name: 'EndingPageCount',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.EndingPageCount || null,
            }),
            FileNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'File Number',
                name: 'FileNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(150) ],
                validators: { 'maxlength': 150 },
                value: this.imedclaim && this.imedclaim.hasOwnProperty('FileNumber') && this.imedclaim.FileNumber != null ? this.imedclaim.FileNumber.toString() : '',
            }),
            FirstName: new DynamicField({
                formGroup: this.formGroup,
                label: 'First Name',
                name: 'FirstName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(200) ],
                validators: { 'required': true, 'maxlength': 200 },
                value: this.imedclaim && this.imedclaim.hasOwnProperty('FirstName') && this.imedclaim.FirstName != null ? this.imedclaim.FirstName.toString() : '',
            }),
            HistoryOfAllegedInjury: new DynamicField({
                formGroup: this.formGroup,
                label: 'History Of Alleged Injury',
                name: 'HistoryOfAllegedInjury',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.hasOwnProperty('HistoryOfAllegedInjury') && this.imedclaim.HistoryOfAllegedInjury != null ? this.imedclaim.HistoryOfAllegedInjury : true,
            }),
            ImedClaimStatusId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Imed Claim Status',
                name: 'ImedClaimStatusId',
                options: this.imedClaimStatuses,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [ noZeroRequiredValidator ],
                validators: { 'required': true },
                value: this.imedclaim && this.imedclaim.ImedClaimStatusId || null,
            }),
            Impression: new DynamicField({
                formGroup: this.formGroup,
                label: 'Impression',
                name: 'Impression',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.hasOwnProperty('Impression') && this.imedclaim.Impression != null ? this.imedclaim.Impression : true,
            }),
            Insured: new DynamicField({
                formGroup: this.formGroup,
                label: 'Insured',
                name: 'Insured',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(250) ],
                validators: { 'maxlength': 250 },
                value: this.imedclaim && this.imedclaim.hasOwnProperty('Insured') && this.imedclaim.Insured != null ? this.imedclaim.Insured.toString() : '',
            }),
            JobNumber: new DynamicField({
                formGroup: this.formGroup,
                label: 'Job Number',
                name: 'JobNumber',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(150) ],
                validators: { 'maxlength': 150 },
                value: this.imedclaim && this.imedclaim.hasOwnProperty('JobNumber') && this.imedclaim.JobNumber != null ? this.imedclaim.JobNumber.toString() : '',
            }),
            LastName: new DynamicField({
                formGroup: this.formGroup,
                label: 'Last Name',
                name: 'LastName',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.required, Validators.maxLength(200) ],
                validators: { 'required': true, 'maxlength': 200 },
                value: this.imedclaim && this.imedclaim.hasOwnProperty('LastName') && this.imedclaim.LastName != null ? this.imedclaim.LastName.toString() : '',
            }),
            LegacyClaimId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Legacy Claim',
                name: 'LegacyClaimId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.LegacyClaimId || null,
            }),
            LetterHeadImageId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Letter Head Image',
                name: 'LetterHeadImageId',
                options: this.letterHeadImages,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.LetterHeadImageId || null,
            }),
            MedicalCanvassing: new DynamicField({
                formGroup: this.formGroup,
                label: 'Medical Canvassing',
                name: 'MedicalCanvassing',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.hasOwnProperty('MedicalCanvassing') && this.imedclaim.MedicalCanvassing != null ? this.imedclaim.MedicalCanvassing : false,
            }),
            MergedToId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Merged To',
                name: 'MergedToId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.MergedToId || null,
            }),
            ModifiedById: new DynamicField({
                formGroup: this.formGroup,
                label: 'Modified By',
                name: 'ModifiedById',
                options: this.modifiedBies,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.ModifiedById || null,
            }),
            NeedForFurtherTreatment: new DynamicField({
                formGroup: this.formGroup,
                label: 'Need For Further Treatment',
                name: 'NeedForFurtherTreatment',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.hasOwnProperty('NeedForFurtherTreatment') && this.imedclaim.NeedForFurtherTreatment != null ? this.imedclaim.NeedForFurtherTreatment : false,
            }),
            Notes: new DynamicField({
                formGroup: this.formGroup,
                label: 'Notes',
                name: 'Notes',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.hasOwnProperty('Notes') && this.imedclaim.Notes != null ? this.imedclaim.Notes.toString() : '',
            }),
            OccupationalStatus: new DynamicField({
                formGroup: this.formGroup,
                label: 'Occupational Status',
                name: 'OccupationalStatus',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.hasOwnProperty('OccupationalStatus') && this.imedclaim.OccupationalStatus != null ? this.imedclaim.OccupationalStatus : true,
            }),
            OtherAddressBookId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Other Address Book',
                name: 'OtherAddressBookId',
                options: this.otherAddressBooks,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.OtherAddressBookId || null,
            }),
            OtherIndividualId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Other Individual',
                name: 'OtherIndividualId',
                options: this.otherIndividuals,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.OtherIndividualId || null,
            }),
            OtherIsDefault: new DynamicField({
                formGroup: this.formGroup,
                label: 'Other Is Default',
                name: 'OtherIsDefault',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.hasOwnProperty('OtherIsDefault') && this.imedclaim.OtherIsDefault != null ? this.imedclaim.OtherIsDefault : false,
            }),
            ParaLegalAddressBookId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Para Legal Address Book',
                name: 'ParaLegalAddressBookId',
                options: this.paraLegalAddressBooks,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.ParaLegalAddressBookId || null,
            }),
            ParaLegalIndividualId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Para Legal Individual',
                name: 'ParaLegalIndividualId',
                options: this.paraLegalIndividuals,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.ParaLegalIndividualId || null,
            }),
            ParaLegalIsDefault: new DynamicField({
                formGroup: this.formGroup,
                label: 'Para Legal Is Default',
                name: 'ParaLegalIsDefault',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.hasOwnProperty('ParaLegalIsDefault') && this.imedclaim.ParaLegalIsDefault != null ? this.imedclaim.ParaLegalIsDefault : false,
            }),
            PastMedicalHistory: new DynamicField({
                formGroup: this.formGroup,
                label: 'Past Medical History',
                name: 'PastMedicalHistory',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.hasOwnProperty('PastMedicalHistory') && this.imedclaim.PastMedicalHistory != null ? this.imedclaim.PastMedicalHistory : true,
            }),
            PastSubsequentHistory: new DynamicField({
                formGroup: this.formGroup,
                label: 'Past Subsequent History',
                name: 'PastSubsequentHistory',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.hasOwnProperty('PastSubsequentHistory') && this.imedclaim.PastSubsequentHistory != null ? this.imedclaim.PastSubsequentHistory : true,
            }),
            PhysicalCheckToFacilityRequired: new DynamicField({
                formGroup: this.formGroup,
                label: 'Physical Check To Facility Required',
                name: 'PhysicalCheckToFacilityRequired',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.hasOwnProperty('PhysicalCheckToFacilityRequired') && this.imedclaim.PhysicalCheckToFacilityRequired != null ? this.imedclaim.PhysicalCheckToFacilityRequired : false,
            }),
            PlantiffAttorneyAddressBookId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Plantiff Attorney Address Book',
                name: 'PlantiffAttorneyAddressBookId',
                options: this.plantiffAttorneyAddressBooks,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.PlantiffAttorneyAddressBookId || null,
            }),
            PlantiffAttorneyIndividualId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Plantiff Attorney Individual',
                name: 'PlantiffAttorneyIndividualId',
                options: this.plantiffAttorneyIndividuals,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.PlantiffAttorneyIndividualId || null,
            }),
            PlantiffAttorneyIsDefault: new DynamicField({
                formGroup: this.formGroup,
                label: 'Plantiff Attorney Is Default',
                name: 'PlantiffAttorneyIsDefault',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.hasOwnProperty('PlantiffAttorneyIsDefault') && this.imedclaim.PlantiffAttorneyIsDefault != null ? this.imedclaim.PlantiffAttorneyIsDefault : false,
            }),
            ReportAssignedToId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Report Assigned To',
                name: 'ReportAssignedToId',
                options: this.reportAssignedTos,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.ReportAssignedToId || null,
            }),
            RequestorTypeId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Requestor Type',
                name: 'RequestorTypeId',
                options: this.requestorTypes,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.RequestorTypeId || null,
            }),
            SchedulingAssignedToId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Scheduling Assigned To',
                name: 'SchedulingAssignedToId',
                options: this.schedulingAssignedTos,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.SchedulingAssignedToId || null,
            }),
            Ssn: new DynamicField({
                formGroup: this.formGroup,
                label: 'Ssn',
                name: 'Ssn',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
                validation: [ Validators.maxLength(20) ],
                validators: { 'maxlength': 20 },
                value: this.imedclaim && this.imedclaim.hasOwnProperty('Ssn') && this.imedclaim.Ssn != null ? this.imedclaim.Ssn.toString() : '',
            }),
            StateId: new DynamicField({
                formGroup: this.formGroup,
                label: 'State',
                name: 'StateId',
                options: this.states,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.StateId || null,
            }),
            StrivenSalesOrderId: new DynamicField({
                formGroup: this.formGroup,
                label: 'Striven Sales Order',
                name: 'StrivenSalesOrderId',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.StrivenSalesOrderId || null,
            }),
            WorkStatus: new DynamicField({
                formGroup: this.formGroup,
                label: 'Work Status',
                name: 'WorkStatus',
                options: null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
                validation: [  ],
                validators: {  },
                value: this.imedclaim && this.imedclaim.hasOwnProperty('WorkStatus') && this.imedclaim.WorkStatus != null ? this.imedclaim.WorkStatus : false,
            }),
        };

        this.View = {
            AdditionalInformation: new DynamicLabel({
                label: 'Additional Information',
                value: this.imedclaim && this.imedclaim.hasOwnProperty('AdditionalInformation') && this.imedclaim.AdditionalInformation != null ? this.imedclaim.AdditionalInformation.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            AdjusterAddressBookId: new DynamicLabel({
                label: 'Adjuster Address Book',
                value: getMetaItemValue(this.adjusterAddressBooks as unknown as IMetaItem[], this.imedclaim && this.imedclaim.hasOwnProperty('AdjusterAddressBookId') ? this.imedclaim.AdjusterAddressBookId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            AdjusterIndividualId: new DynamicLabel({
                label: 'Adjuster Individual',
                value: getMetaItemValue(this.adjusterIndividuals as unknown as IMetaItem[], this.imedclaim && this.imedclaim.hasOwnProperty('AdjusterIndividualId') ? this.imedclaim.AdjusterIndividualId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            AdjusterIsDefault: new DynamicLabel({
                label: 'Adjuster Is Default',
                value: this.imedclaim && this.imedclaim.hasOwnProperty('AdjusterIsDefault') && this.imedclaim.AdjusterIsDefault != null ? this.imedclaim.AdjusterIsDefault : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            AllStates: new DynamicLabel({
                label: 'All States',
                value: this.imedclaim && this.imedclaim.hasOwnProperty('AllStates') && this.imedclaim.AllStates != null ? this.imedclaim.AllStates : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            Archived: new DynamicLabel({
                label: 'Archived',
                value: this.imedclaim && this.imedclaim.hasOwnProperty('Archived') && this.imedclaim.Archived != null ? this.imedclaim.Archived : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            AssociatedDomain: new DynamicLabel({
                label: 'Associated Domain',
                value: this.imedclaim && this.imedclaim.hasOwnProperty('AssociatedDomain') && this.imedclaim.AssociatedDomain != null ? this.imedclaim.AssociatedDomain.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            BeginningPageCount: new DynamicLabel({
                label: 'Beginning Page Count',
                value: this.imedclaim && this.imedclaim.BeginningPageCount || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            Birthdate: new DynamicLabel({
                label: 'Birthdate',
                value: this.imedclaim && this.imedclaim.Birthdate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            CarrierPartyAddressBookId: new DynamicLabel({
                label: 'Carrier Party Address Book',
                value: getMetaItemValue(this.carrierPartyAddressBooks as unknown as IMetaItem[], this.imedclaim && this.imedclaim.hasOwnProperty('CarrierPartyAddressBookId') ? this.imedclaim.CarrierPartyAddressBookId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            CarrierPartyIndividualId: new DynamicLabel({
                label: 'Carrier Party Individual',
                value: getMetaItemValue(this.carrierPartyIndividuals as unknown as IMetaItem[], this.imedclaim && this.imedclaim.hasOwnProperty('CarrierPartyIndividualId') ? this.imedclaim.CarrierPartyIndividualId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            CarrierPartyIsDefault: new DynamicLabel({
                label: 'Carrier Party Is Default',
                value: this.imedclaim && this.imedclaim.hasOwnProperty('CarrierPartyIsDefault') && this.imedclaim.CarrierPartyIsDefault != null ? this.imedclaim.CarrierPartyIsDefault : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            CarriersCounselAddressBookId: new DynamicLabel({
                label: 'Carriers Counsel Address Book',
                value: getMetaItemValue(this.carriersCounselAddressBooks as unknown as IMetaItem[], this.imedclaim && this.imedclaim.hasOwnProperty('CarriersCounselAddressBookId') ? this.imedclaim.CarriersCounselAddressBookId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            CarriersCounselIndividualId: new DynamicLabel({
                label: 'Carriers Counsel Individual',
                value: getMetaItemValue(this.carriersCounselIndividuals as unknown as IMetaItem[], this.imedclaim && this.imedclaim.hasOwnProperty('CarriersCounselIndividualId') ? this.imedclaim.CarriersCounselIndividualId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            CarriersCounselIsDefault: new DynamicLabel({
                label: 'Carriers Counsel Is Default',
                value: this.imedclaim && this.imedclaim.hasOwnProperty('CarriersCounselIsDefault') && this.imedclaim.CarriersCounselIsDefault != null ? this.imedclaim.CarriersCounselIsDefault : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            CaseSettledDate: new DynamicLabel({
                label: 'Case Settled Date',
                value: this.imedclaim && this.imedclaim.CaseSettledDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            CaseTransferredDate: new DynamicLabel({
                label: 'Case Transferred Date',
                value: this.imedclaim && this.imedclaim.CaseTransferredDate || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            CausalRelationship: new DynamicLabel({
                label: 'Causal Relationship',
                value: this.imedclaim && this.imedclaim.hasOwnProperty('CausalRelationship') && this.imedclaim.CausalRelationship != null ? this.imedclaim.CausalRelationship : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            ClaimantsAttorneyAddressBookId: new DynamicLabel({
                label: 'Claimants Attorney Address Book',
                value: getMetaItemValue(this.claimantsAttorneyAddressBooks as unknown as IMetaItem[], this.imedclaim && this.imedclaim.hasOwnProperty('ClaimantsAttorneyAddressBookId') ? this.imedclaim.ClaimantsAttorneyAddressBookId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ClaimantsAttorneyIndividualId: new DynamicLabel({
                label: 'Claimants Attorney Individual',
                value: getMetaItemValue(this.claimantsAttorneyIndividuals as unknown as IMetaItem[], this.imedclaim && this.imedclaim.hasOwnProperty('ClaimantsAttorneyIndividualId') ? this.imedclaim.ClaimantsAttorneyIndividualId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ClaimantsAttorneyIsDefault: new DynamicLabel({
                label: 'Claimants Attorney Is Default',
                value: this.imedclaim && this.imedclaim.hasOwnProperty('ClaimantsAttorneyIsDefault') && this.imedclaim.ClaimantsAttorneyIsDefault != null ? this.imedclaim.ClaimantsAttorneyIsDefault : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            ClaimNumber: new DynamicLabel({
                label: 'Claim Number',
                value: this.imedclaim && this.imedclaim.hasOwnProperty('ClaimNumber') && this.imedclaim.ClaimNumber != null ? this.imedclaim.ClaimNumber.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            ClaimTypeId: new DynamicLabel({
                label: 'Claim Type',
                value: getMetaItemValue(this.claimTypes as unknown as IMetaItem[], this.imedclaim && this.imedclaim.hasOwnProperty('ClaimTypeId') ? this.imedclaim.ClaimTypeId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            CompilationAssignedToId: new DynamicLabel({
                label: 'Compilation Assigned To',
                value: getMetaItemValue(this.compilationAssignedTos as unknown as IMetaItem[], this.imedclaim && this.imedclaim.hasOwnProperty('CompilationAssignedToId') ? this.imedclaim.CompilationAssignedToId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            CreatedById: new DynamicLabel({
                label: 'Created By',
                value: getMetaItemValue(this.createdBies as unknown as IMetaItem[], this.imedclaim && this.imedclaim.hasOwnProperty('CreatedById') ? this.imedclaim.CreatedById : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            CurrentCourseOfTreatment: new DynamicLabel({
                label: 'Current Course Of Treatment',
                value: this.imedclaim && this.imedclaim.hasOwnProperty('CurrentCourseOfTreatment') && this.imedclaim.CurrentCourseOfTreatment != null ? this.imedclaim.CurrentCourseOfTreatment : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            CurrentSymptomsAndCompliants: new DynamicLabel({
                label: 'Current Symptoms And Compliants',
                value: this.imedclaim && this.imedclaim.hasOwnProperty('CurrentSymptomsAndCompliants') && this.imedclaim.CurrentSymptomsAndCompliants != null ? this.imedclaim.CurrentSymptomsAndCompliants : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            DateCreated: new DynamicLabel({
                label: 'Date Created',
                value: this.imedclaim && this.imedclaim.DateCreated || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            DateModified: new DynamicLabel({
                label: 'Date Modified',
                value: this.imedclaim && this.imedclaim.DateModified || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            DateofAllegedAccidentOrInjury: new DynamicLabel({
                label: 'Dateof Alleged Accident Or Injury',
                value: this.imedclaim && this.imedclaim.DateofAllegedAccidentOrInjury || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Datepicker,
                    scale: null,
                }),
            }),
            DefenseAttorneyAddressBookId: new DynamicLabel({
                label: 'Defense Attorney Address Book',
                value: getMetaItemValue(this.defenseAttorneyAddressBooks as unknown as IMetaItem[], this.imedclaim && this.imedclaim.hasOwnProperty('DefenseAttorneyAddressBookId') ? this.imedclaim.DefenseAttorneyAddressBookId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            DefenseAttorneyIndividualId: new DynamicLabel({
                label: 'Defense Attorney Individual',
                value: getMetaItemValue(this.defenseAttorneyIndividuals as unknown as IMetaItem[], this.imedclaim && this.imedclaim.hasOwnProperty('DefenseAttorneyIndividualId') ? this.imedclaim.DefenseAttorneyIndividualId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            DefenseAttorneyIsDefault: new DynamicLabel({
                label: 'Defense Attorney Is Default',
                value: this.imedclaim && this.imedclaim.hasOwnProperty('DefenseAttorneyIsDefault') && this.imedclaim.DefenseAttorneyIsDefault != null ? this.imedclaim.DefenseAttorneyIsDefault : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            DegreeOfDisability: new DynamicLabel({
                label: 'Degree Of Disability',
                value: this.imedclaim && this.imedclaim.hasOwnProperty('DegreeOfDisability') && this.imedclaim.DegreeOfDisability != null ? this.imedclaim.DegreeOfDisability : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            DocumentFolder: new DynamicLabel({
                label: 'Document Folder',
                value: this.imedclaim && this.imedclaim.hasOwnProperty('DocumentFolder') && this.imedclaim.DocumentFolder != null ? this.imedclaim.DocumentFolder.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            EmployerAddressBookId: new DynamicLabel({
                label: 'Employer Address Book',
                value: getMetaItemValue(this.employerAddressBooks as unknown as IMetaItem[], this.imedclaim && this.imedclaim.hasOwnProperty('EmployerAddressBookId') ? this.imedclaim.EmployerAddressBookId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            EmployerIndividualId: new DynamicLabel({
                label: 'Employer Individual',
                value: getMetaItemValue(this.employerIndividuals as unknown as IMetaItem[], this.imedclaim && this.imedclaim.hasOwnProperty('EmployerIndividualId') ? this.imedclaim.EmployerIndividualId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            EmployerIsDefault: new DynamicLabel({
                label: 'Employer Is Default',
                value: this.imedclaim && this.imedclaim.hasOwnProperty('EmployerIsDefault') && this.imedclaim.EmployerIsDefault != null ? this.imedclaim.EmployerIsDefault : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            EndingPageCount: new DynamicLabel({
                label: 'Ending Page Count',
                value: this.imedclaim && this.imedclaim.EndingPageCount || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            FileNumber: new DynamicLabel({
                label: 'File Number',
                value: this.imedclaim && this.imedclaim.hasOwnProperty('FileNumber') && this.imedclaim.FileNumber != null ? this.imedclaim.FileNumber.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            FirstName: new DynamicLabel({
                label: 'First Name',
                value: this.imedclaim && this.imedclaim.hasOwnProperty('FirstName') && this.imedclaim.FirstName != null ? this.imedclaim.FirstName.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            HistoryOfAllegedInjury: new DynamicLabel({
                label: 'History Of Alleged Injury',
                value: this.imedclaim && this.imedclaim.hasOwnProperty('HistoryOfAllegedInjury') && this.imedclaim.HistoryOfAllegedInjury != null ? this.imedclaim.HistoryOfAllegedInjury : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            ImedClaimStatusId: new DynamicLabel({
                label: 'Imed Claim Status',
                value: getMetaItemValue(this.imedClaimStatuses as unknown as IMetaItem[], this.imedclaim && this.imedclaim.hasOwnProperty('ImedClaimStatusId') ? this.imedclaim.ImedClaimStatusId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            Impression: new DynamicLabel({
                label: 'Impression',
                value: this.imedclaim && this.imedclaim.hasOwnProperty('Impression') && this.imedclaim.Impression != null ? this.imedclaim.Impression : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            Insured: new DynamicLabel({
                label: 'Insured',
                value: this.imedclaim && this.imedclaim.hasOwnProperty('Insured') && this.imedclaim.Insured != null ? this.imedclaim.Insured.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            JobNumber: new DynamicLabel({
                label: 'Job Number',
                value: this.imedclaim && this.imedclaim.hasOwnProperty('JobNumber') && this.imedclaim.JobNumber != null ? this.imedclaim.JobNumber.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            LastName: new DynamicLabel({
                label: 'Last Name',
                value: this.imedclaim && this.imedclaim.hasOwnProperty('LastName') && this.imedclaim.LastName != null ? this.imedclaim.LastName.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            LegacyClaimId: new DynamicLabel({
                label: 'Legacy Claim',
                value: this.imedclaim && this.imedclaim.LegacyClaimId || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            LetterHeadImageId: new DynamicLabel({
                label: 'Letter Head Image',
                value: getMetaItemValue(this.letterHeadImages as unknown as IMetaItem[], this.imedclaim && this.imedclaim.hasOwnProperty('LetterHeadImageId') ? this.imedclaim.LetterHeadImageId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            MedicalCanvassing: new DynamicLabel({
                label: 'Medical Canvassing',
                value: this.imedclaim && this.imedclaim.hasOwnProperty('MedicalCanvassing') && this.imedclaim.MedicalCanvassing != null ? this.imedclaim.MedicalCanvassing : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            MergedToId: new DynamicLabel({
                label: 'Merged To',
                value: this.imedclaim && this.imedclaim.MergedToId || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            ModifiedById: new DynamicLabel({
                label: 'Modified By',
                value: getMetaItemValue(this.modifiedBies as unknown as IMetaItem[], this.imedclaim && this.imedclaim.hasOwnProperty('ModifiedById') ? this.imedclaim.ModifiedById : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            NeedForFurtherTreatment: new DynamicLabel({
                label: 'Need For Further Treatment',
                value: this.imedclaim && this.imedclaim.hasOwnProperty('NeedForFurtherTreatment') && this.imedclaim.NeedForFurtherTreatment != null ? this.imedclaim.NeedForFurtherTreatment : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            Notes: new DynamicLabel({
                label: 'Notes',
                value: this.imedclaim && this.imedclaim.hasOwnProperty('Notes') && this.imedclaim.Notes != null ? this.imedclaim.Notes.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: InputTypes.Textarea,
                    scale: null,
                }),
            }),
            OccupationalStatus: new DynamicLabel({
                label: 'Occupational Status',
                value: this.imedclaim && this.imedclaim.hasOwnProperty('OccupationalStatus') && this.imedclaim.OccupationalStatus != null ? this.imedclaim.OccupationalStatus : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            OtherAddressBookId: new DynamicLabel({
                label: 'Other Address Book',
                value: getMetaItemValue(this.otherAddressBooks as unknown as IMetaItem[], this.imedclaim && this.imedclaim.hasOwnProperty('OtherAddressBookId') ? this.imedclaim.OtherAddressBookId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            OtherIndividualId: new DynamicLabel({
                label: 'Other Individual',
                value: getMetaItemValue(this.otherIndividuals as unknown as IMetaItem[], this.imedclaim && this.imedclaim.hasOwnProperty('OtherIndividualId') ? this.imedclaim.OtherIndividualId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            OtherIsDefault: new DynamicLabel({
                label: 'Other Is Default',
                value: this.imedclaim && this.imedclaim.hasOwnProperty('OtherIsDefault') && this.imedclaim.OtherIsDefault != null ? this.imedclaim.OtherIsDefault : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            ParaLegalAddressBookId: new DynamicLabel({
                label: 'Para Legal Address Book',
                value: getMetaItemValue(this.paraLegalAddressBooks as unknown as IMetaItem[], this.imedclaim && this.imedclaim.hasOwnProperty('ParaLegalAddressBookId') ? this.imedclaim.ParaLegalAddressBookId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ParaLegalIndividualId: new DynamicLabel({
                label: 'Para Legal Individual',
                value: getMetaItemValue(this.paraLegalIndividuals as unknown as IMetaItem[], this.imedclaim && this.imedclaim.hasOwnProperty('ParaLegalIndividualId') ? this.imedclaim.ParaLegalIndividualId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            ParaLegalIsDefault: new DynamicLabel({
                label: 'Para Legal Is Default',
                value: this.imedclaim && this.imedclaim.hasOwnProperty('ParaLegalIsDefault') && this.imedclaim.ParaLegalIsDefault != null ? this.imedclaim.ParaLegalIsDefault : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            PastMedicalHistory: new DynamicLabel({
                label: 'Past Medical History',
                value: this.imedclaim && this.imedclaim.hasOwnProperty('PastMedicalHistory') && this.imedclaim.PastMedicalHistory != null ? this.imedclaim.PastMedicalHistory : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            PastSubsequentHistory: new DynamicLabel({
                label: 'Past Subsequent History',
                value: this.imedclaim && this.imedclaim.hasOwnProperty('PastSubsequentHistory') && this.imedclaim.PastSubsequentHistory != null ? this.imedclaim.PastSubsequentHistory : true,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            PhysicalCheckToFacilityRequired: new DynamicLabel({
                label: 'Physical Check To Facility Required',
                value: this.imedclaim && this.imedclaim.hasOwnProperty('PhysicalCheckToFacilityRequired') && this.imedclaim.PhysicalCheckToFacilityRequired != null ? this.imedclaim.PhysicalCheckToFacilityRequired : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            PlantiffAttorneyAddressBookId: new DynamicLabel({
                label: 'Plantiff Attorney Address Book',
                value: getMetaItemValue(this.plantiffAttorneyAddressBooks as unknown as IMetaItem[], this.imedclaim && this.imedclaim.hasOwnProperty('PlantiffAttorneyAddressBookId') ? this.imedclaim.PlantiffAttorneyAddressBookId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            PlantiffAttorneyIndividualId: new DynamicLabel({
                label: 'Plantiff Attorney Individual',
                value: getMetaItemValue(this.plantiffAttorneyIndividuals as unknown as IMetaItem[], this.imedclaim && this.imedclaim.hasOwnProperty('PlantiffAttorneyIndividualId') ? this.imedclaim.PlantiffAttorneyIndividualId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            PlantiffAttorneyIsDefault: new DynamicLabel({
                label: 'Plantiff Attorney Is Default',
                value: this.imedclaim && this.imedclaim.hasOwnProperty('PlantiffAttorneyIsDefault') && this.imedclaim.PlantiffAttorneyIsDefault != null ? this.imedclaim.PlantiffAttorneyIsDefault : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
            ReportAssignedToId: new DynamicLabel({
                label: 'Report Assigned To',
                value: getMetaItemValue(this.reportAssignedTos as unknown as IMetaItem[], this.imedclaim && this.imedclaim.hasOwnProperty('ReportAssignedToId') ? this.imedclaim.ReportAssignedToId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            RequestorTypeId: new DynamicLabel({
                label: 'Requestor Type',
                value: getMetaItemValue(this.requestorTypes as unknown as IMetaItem[], this.imedclaim && this.imedclaim.hasOwnProperty('RequestorTypeId') ? this.imedclaim.RequestorTypeId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            SchedulingAssignedToId: new DynamicLabel({
                label: 'Scheduling Assigned To',
                value: getMetaItemValue(this.schedulingAssignedTos as unknown as IMetaItem[], this.imedclaim && this.imedclaim.hasOwnProperty('SchedulingAssignedToId') ? this.imedclaim.SchedulingAssignedToId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            Ssn: new DynamicLabel({
                label: 'Ssn',
                value: this.imedclaim && this.imedclaim.hasOwnProperty('Ssn') && this.imedclaim.Ssn != null ? this.imedclaim.Ssn.toString() : '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Input,
                    inputType: null,
                    scale: null,
                }),
            }),
            StateId: new DynamicLabel({
                label: 'State',
                value: getMetaItemValue(this.states as unknown as IMetaItem[], this.imedclaim && this.imedclaim.hasOwnProperty('StateId') ? this.imedclaim.StateId : null) ?? '',
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Select,
                    inputType: null,
                    scale: null,
                }),
            }),
            StrivenSalesOrderId: new DynamicLabel({
                label: 'Striven Sales Order',
                value: this.imedclaim && this.imedclaim.StrivenSalesOrderId || null,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Numeric,
                    inputType: NumericInputTypes.Integer,
                    scale: null,
                }),
            }),
            WorkStatus: new DynamicLabel({
                label: 'Work Status',
                value: this.imedclaim && this.imedclaim.hasOwnProperty('WorkStatus') && this.imedclaim.WorkStatus != null ? this.imedclaim.WorkStatus : false,
                type: new DynamicFieldType({
                    fieldType: DynamicFieldTypes.Checkbox,
                    inputType: null,
                    scale: null,
                }),
            }),
        };

    }
}


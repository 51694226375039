import { Validators } from '@angular/forms';

import {
    DynamicField,
    DynamicFieldType,
    DynamicFieldTypes,
    SelectInputTypes,
    IDynamicFieldType,
    IDynamicField,
} from '@mt-ng2/dynamic-form';

import { AddressDynamicControls, IAddressDynamicControlsParameters } from '../form-controls/address.form-controls';
import { IAddress } from '../interfaces/address';
import { IAddressType } from '../interfaces/address-type';

export interface IAddressDynamicControlsParametersPartial extends IAddressDynamicControlsParameters {
    addressTypes?: IAddressType[];
    selectedAddressTypeIdsArray?: number[];
}

export class AddressDynamicControlsPartial extends AddressDynamicControls {
    constructor(addressPartial?: IAddress, additionalParameters?: IAddressDynamicControlsParametersPartial) {
        super(addressPartial, additionalParameters);

        this.Form.AssociatedAddressTypes = new DynamicField({
            formGroup: this.formGroup,
            label: 'Select Address Types',
            name: 'AssociatedAddressTypes',
            options: additionalParameters?.addressTypes,
            type: new DynamicFieldType({
                fieldType: DynamicFieldTypes.Select,
                inputType: SelectInputTypes.MultiselectDropdown,
                maxToShowInSelectedText: 1,
                showSelectAllButtons: true,
            } as IDynamicFieldType),
            validation: [Validators.required],
            validators: { required: true },
            value: additionalParameters?.selectedAddressTypeIdsArray,
        } as IDynamicField);

        (<DynamicField>this.Form.Archived).labelHtml = '<label>Archive</label>';
        (<DynamicField>this.Form.Address1).setRequired(true);
        (<DynamicField>this.Form.City).setRequired(true);
        (<DynamicField>this.Form.StateId).setRequired(true);
        (<DynamicField>this.Form.Zip).setRequired(true);
    }
}
